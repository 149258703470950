.DictionaryField {
    overflow: auto;
    max-height: 300px;
    width: 100%;
}

.DictionaryField_key {
    padding: 6px;
    padding-right: 12px;
}

.DictionaryField_key_readonly {
    padding: 6px;
    padding-right: 12px;
    background-color: lightgray;
}

.DictionaryField_value {
    padding: 6px;
}
