:root {
    --vh: 1vh;
}

* {
    box-sizing: border-box;
    --vh: inherit;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    background: rgba(0, 0, 0, 0.07);
}

.piPanel_title {
    padding: 16px 24px;
}

.BottomActionsBar button:not(:first-child) {
    margin-left: 12px;
}

.truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

@media screen and (min-width: 400px) {
}

@media screen and (min-width: 600px) {
    .body {
        display: flex; /* establish flex container */
        flex-direction: column; /* stack flex items vertically */
        justify-content: center; /* center items vertically, in this case */
        align-items: center; /* center items horizontally, in this case */
    }
}

/*.AppComponent {*/
/*    zoom: 80%;*/
/*}*/

/*@media only screen and (max-width: 600px) {*/
/*    .AppComponent {*/
/*        border: 2px solid red;*/
/*        zoom: 100%;*/
/*    }*/
/*}*/