.LookupField {
    display: flex;
    padding-top: 16px;
    padding-bottom: 8px;
}

.LookupField_field {
    width: 100%;
}

.LookupField_value {
    min-height: 18px;
    width: 100%;
}
